<template>
  <div>
    <!--Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="1400">
      <v-card flat class="white">
        <v-card-title class="text-h5 secondary--text"
          >Teacher Payment Information <span v-if="isDuplicate">&nbsp;(Duplicate)</span
          ><v-spacer />
          <v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" lazy-validation>
            <v-container fluid>
              <v-row dense>
                <!--Month Picker-->
                <v-col cols="12" md="2">
                  <v-autocomplete
                    :items="valueListsStore.months"
                    label="Payment Month"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_month"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>

                <!--Year-->
                <v-col cols="12" sm="12" md="2">
                  <v-text-field
                    v-model="d_year"
                    label="Payment Year"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>

                <!--Review Month-->
                <v-col cols="12" sm="12" md="2">
                  <text-display
                    :displayLabel="'Review Month'"
                    :displayString="$_monthNumToText(f_reviewMonth)"
                  />
                </v-col>

                <!--Review Year-->
                <v-col cols="12" sm="12" md="2">
                  <text-display
                    :displayLabel="'Review Year'"
                    :displayNumber="d_reviewYear ? d_reviewYear : 0"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Provider-->
                <v-col cols="12" sm="12" md="6">
                  <text-display
                    :displayLabel="'Provider Name'"
                    :displayString="d_providerNameLicense"
                  />
                </v-col>

                <!--Provider License Number-->
                <v-col cols="12" sm="12" md="2">
                  <text-display
                    :displayLabel="'License Number'"
                    :displayString="d_providerLicenseNumber"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Provider Business Name-->
                <v-col cols="12" sm="12" md="6">
                  <text-display
                    :displayLabel="'Provider Business Name'"
                    :displayString="d_providerNameFederal"
                  />
                </v-col>
                <!--Classroom BK-->
                <v-col cols="12" sm="2">
                  <text-display :displayLabel="'Classroom BK'" :displayString="$_yesNo(f_bk)" />
                </v-col>
                <!--Classroom-->
                <v-col cols="12" sm="2">
                  <text-display :displayLabel="'Classroom'" :displayString="d_classroomName" />
                </v-col>
                <!--Teacher Name-->
                <v-col cols="12" sm="2">
                  <text-display :displayLabel="'Teacher Name'" :displayString="d_teacherName" />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Left Side-->
                <v-col cols="12" md="8">
                  <v-row dense>
                    <!--Internal Note-->
                    <v-col cols="12" sm="12">
                      <v-textarea
                        rows="2"
                        v-model="d_noteInternal"
                        label="Internal Note"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--External Note-->
                    <v-col cols="12" sm="12">
                      <v-textarea
                        rows="2"
                        v-model="d_noteExternal"
                        label="External Note"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Follow Up-->
                    <v-col cols="12" sm="12" md="8">
                      <v-autocomplete
                        :items="valueListsStore.valueListItems[36]"
                        item-text="d_name"
                        item-value="id"
                        label="Follow Up Reason"
                        placeholder=" "
                        persistent-placeholder
                        v-model="f_followup"
                        key="id"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Type-->
                    <v-col cols="12" sm="4">
                      <v-autocomplete
                        :items="valueListsStore.valueListItems[28]"
                        item-text="d_name"
                        item-value="d_value"
                        label="Type"
                        placeholder=" "
                        persistent-placeholder
                        v-model="f_type"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>

                    <!--Status-->
                    <v-col cols="12" sm="4">
                      <v-autocomplete
                        v-if="usersStore.authUser.f_teacherPaymentStatus === 1"
                        :items="valueListsStore.teacherPaymentStatus"
                        item-text="value"
                        item-value="id"
                        label="Status"
                        placeholder=" "
                        persistent-placeholder
                        v-model="f_status"
                        key="id"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                      <text-display
                        v-else
                        :displayLabel="'Status'"
                        :displayString="$_teacherPaymentStatus(f_status)"
                      />
                    </v-col>
                    <!--Remove Payment-->
                    <v-col cols="12" sm="12" md="3">
                      <v-select
                        v-if="mode !== 1 && usersStore.authUser.f_admin === 1"
                        :items="[{ text: 'Yes', value: '0' }]"
                        item-text="text"
                        item-value="value"
                        label="Remove Teacher Payment?"
                        placeholder=" "
                        persistent-placeholder
                        v-model="f_show"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row class="pb-4">
                    <v-col cols="12" sm="12">
                      <span class="text-caption grey--text"
                        >Created: {{ $_formatTimestamp(created_at) }} &#8226; Modified:
                        {{ $_formatTimestamp(updated_at) }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" md="1"> </v-col>

                <!--Right Side-->
                <v-col cols="12" md="3">
                  <v-row dense>
                    <v-col cols="12" sm="12" md="6">
                      <!--Override Amount-->
                      <v-text-field
                        v-model="d_amountOverride"
                        label="Override Amount"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>

                    <v-col cols="12" sm="12" md="6">
                      <!--Total Amount-->
                      <text-display
                        :displayBold="true"
                        :displayLabel="'Payment Amount'"
                        :displayString="$_formatMoney(paymentAmount)"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Funding Source - Payment-->
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        :items="fundingSourcesStore.fundingSourcesValueList"
                        label="Funding Source - Payment"
                        placeholder=" "
                        persistent-placeholder
                        item-text="d_name"
                        item-value="id"
                        v-model="fk_fundingSourceID"
                        clearable
                        clear-icon="fal fa-times-circle"
                        @input="pullFundingSource(fk_fundingSourceID)"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <!--Funding Code-->
                      <text-display :displayLabel="'Funding Code'" :displayString="d_fundingCode" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TextDisplay from "@/components/TextDisplay";

const { mapFields } = createHelpers({
  getterType: "teacherPaymentsStore/getEntryField",
  mutationType: "teacherPaymentsStore/updateEntryField"
});

export default {
  name: "PaymentEntry",
  mixins: [mixin],
  computed: {
    ...mapState({
      teacherPaymentsStore: state => state.teacherPaymentsStore,
      providersStore: state => state.providersStore,
      valueListsStore: state => state.valueListsStore,
      fundingSourcesStore: state => state.fundingSourcesStore,
      usersStore: state => state.usersStore
    }),
    ...mapFields([
      "show",
      "show2",
      "valid",
      "mode",
      "id",
      "tab",
      "isDuplicate",
      "fk_teacherReviewID",
      "fk_providerID",
      "fk_classroomHistoryID",
      "fk_teacherID",
      "fk_teacherLicenseID",
      "fk_fundingSourceID",
      "f_reviewMonth",
      "d_reviewYear",
      "f_month",
      "d_year",
      "d_date",
      "d_providerTaxID",
      "d_providerNameLicense",
      "d_providerNameFederal",
      "d_providerLicenseNumber",
      "d_classroomNumber",
      "d_classroomName",
      "d_teacherName",
      "d_supplementAmountTotal",
      "d_backPayAmountTotal",
      "d_amountOverride",
      "d_amountTotal",
      "d_noteExternal",
      "d_noteInternal",
      "d_startDate",
      "d_yearsExperience",
      "d_licenseIssueDate",
      "d_licenseExpirationDate",
      "d_licenseType",
      "d_fundingCodeSource",
      "d_fundingCodeSuffix",
      "d_fundingCode",
      "f_bk",
      "f_adequate",
      "f_monthSalaryCheck",
      "f_followup",
      "f_type",
      "f_status",
      "f_show",
      "created_at",
      "updated_at"
    ]),
    paymentAmount() {
      if (this.d_amountOverride < 0 || this.d_amountOverride > 0) {
        return this.d_amountOverride;
      }
      return this.d_supplementAmountTotal;
    }
  },
  components: {
    TextDisplay
  },
  data() {
    return {
      menu: false
    };
  },
  methods: {
    resetEntry() {
      this.$store.dispatch("teacherPaymentsStore/resetEntry");
    },

    async loadValueLists() {
      await this.$store.dispatch("providersStore/valueList");
    },

    async pullFundingSource(id) {
      await this.$store.dispatch("fundingSourcesStore/read", id);
      const fundingSource = await this.$store.getters["fundingSourcesStore/getFundingSource"];
      this.d_fundingCodeSource = fundingSource.d_code;
      this.d_fundingCodeSuffix = "00";
      this.d_fundingCode = this.d_fundingCodeSource + "-" + this.d_fundingCodeSuffix;
      //this.d_fundingCode = fundingSource.d_code;
    },

    cancelEntry() {
      this.show = false;
      this.resetEntry();
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        this.d_amountTotal = this.paymentAmount;
        const entry = this.$store.getters["teacherPaymentsStore/getEntry"];
        /* Create */
        if (this.mode === 1) {
          await this.$store.dispatch("teacherPaymentsStore/create", entry);
          /* Grab filters */
          const filters = await this.$store.getters["teacherPaymentsStore/getFilters"];
          /* Records based on filters */
          await this.$store.dispatch("teacherPaymentsStore/fetch", filters);
        } else {
          /* Edit */
          await this.$store.dispatch("teacherPaymentsStore/update", entry);
        }
        this.cancelEntry();
        /* Reload List */
        this.$store.dispatch("progressStore/set", true);
        const filters = this.$store.getters["teacherPaymentsStore/getFilters"];
        await this.$store.dispatch("teacherPaymentsStore/fetch", filters);
        this.$store.dispatch("progressStore/set", false);
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
