<template>
  <div>
    <v-tooltip
      top
      :color="teacherPaymentsStore.filters.selectedID.length > 0 ? 'primary' : 'grey lighten-1'"
    >
      <template #activator="{ on }">
        <v-btn
          icon
          v-on="on"
          @click="openEntry(teacherPaymentsStore.filters.selectedID.length > 0 ? 1 : 0)"
        >
          <v-icon
            :color="
              teacherPaymentsStore.filters.selectedID.length > 0 ? 'primary' : 'grey lighten-1'
            "
          >
            fal fa-check-double
          </v-icon>
        </v-btn>
      </template>
      <span>Change Selected</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog v-model="show2" persistent max-width="600">
      <v-card flat class="white">
        <v-card-title class="text-h5 secondary--text"
          >Teacher Payments Change Selected<v-spacer /><v-btn
            text
            rounded
            outlined
            @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" lazy-validation>
            <v-container fluid>
              <v-row desne>
                <!--Follow Up-->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="valueListsStore.valueListItems[36]"
                    item-text="d_name"
                    item-value="id"
                    label="Follow Up Reason"
                    placeholder=" "
                    persistent-placeholder
                    v-model="f_followup"
                    key="id"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <v-row>
                <!--No Follow Up-->
                <v-col class="pt-0 pb-4" cols="12" sm="12">
                  <v-checkbox
                    v-model="noFollowup"
                    label="Clear Follow Up"
                    value="Yes"
                    color="primary"
                    class="pr-2"
                    hide-details
                  >
                  </v-checkbox>
                </v-col>
              </v-row>

              <v-row dense>
                <!--Status-->
                <v-col class="pt-4" cols="12" sm="12">
                  <v-select
                    :items="valueListsStore.teacherPaymentStatus"
                    item-text="value"
                    item-value="id"
                    label="Status"
                    placeholder=" "
                    persistent-placeholder
                    v-model="f_status"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TextDisplay from "@/components/TextDisplay";

const { mapFields } = createHelpers({
  getterType: "teacherPaymentsStore/getEntryField",
  mutationType: "teacherPaymentsStore/updateEntryField"
});

export default {
  name: "TeacherPaymentsMassChange",
  mixins: [mixin],
  computed: {
    ...mapState({
      teacherPaymentsStore: state => state.teacherPaymentsStore,
      valueListsStore: state => state.valueListsStore
    }),
    ...mapFields([
      "show",
      "show2",
      "valid",
      "mode",
      "id",
      "tab",

      "f_followup",
      "f_status",
      "noFollowup"
    ])
  },
  components: {
    TextDisplay
  },
  data() {
    return {};
  },
  methods: {
    resetEntry() {
      this.$store.dispatch("teacherPaymentsStore/resetEntry");
    },

    cancelEntry() {
      this.show2 = false;
      this.resetEntry();
    },

    async openEntry(active) {
      if (active === 1) {
        this.resetEntry();
        this.f_status = "";
        await this.loadValueLists();
        this.show2 = true;
      }
    },

    async loadValueLists() {
      const vldata = {
        id: [36]
      };
      await this.$store.dispatch("valueListsStore/items", vldata);
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters["teacherPaymentsStore/getEntry"];
        let filters = this.$store.getters["teacherPaymentsStore/getFilters"];
        const data = {
          f_status: entry.f_status,
          f_followup: entry.f_followup,
          noFollowup: entry.noFollowup,
          items: filters.selectedID
        };
        await this.$store.dispatch("teacherPaymentsStore/massUpdate", data);
        this.cancelEntry();

        this.$store.dispatch("progressStore/set", true);
        /* Grab filters */
        filters = this.$store.getters["teacherPaymentsStore/getFilters"];
        /* Records based on filters */
        await this.$store.dispatch("teacherPaymentsStore/fetch", filters);
        this.$store.dispatch("teacherPaymentsStore/resetMassSelection");
        this.$store.dispatch("progressStore/set", false);
        this.cancelEntry();
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
